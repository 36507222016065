// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-admin-js": () => import("./../../../src/pages/admin.js" /* webpackChunkName: "component---src-pages-admin-js" */),
  "component---src-pages-anmalan-js": () => import("./../../../src/pages/anmalan.js" /* webpackChunkName: "component---src-pages-anmalan-js" */),
  "component---src-pages-banan-js": () => import("./../../../src/pages/banan.js" /* webpackChunkName: "component---src-pages-banan-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-loppet-js": () => import("./../../../src/pages/loppet.js" /* webpackChunkName: "component---src-pages-loppet-js" */),
  "component---src-pages-resultat-2021-index-js": () => import("./../../../src/pages/resultat/2021/index.js" /* webpackChunkName: "component---src-pages-resultat-2021-index-js" */),
  "component---src-pages-resultat-2021-spurtpris-js": () => import("./../../../src/pages/resultat/2021/spurtpris.js" /* webpackChunkName: "component---src-pages-resultat-2021-spurtpris-js" */),
  "component---src-pages-resultat-2022-index-js": () => import("./../../../src/pages/resultat/2022/index.js" /* webpackChunkName: "component---src-pages-resultat-2022-index-js" */),
  "component---src-pages-resultat-2022-spurtpris-js": () => import("./../../../src/pages/resultat/2022/spurtpris.js" /* webpackChunkName: "component---src-pages-resultat-2022-spurtpris-js" */),
  "component---src-pages-resultat-2023-index-js": () => import("./../../../src/pages/resultat/2023/index.js" /* webpackChunkName: "component---src-pages-resultat-2023-index-js" */),
  "component---src-pages-resultat-2023-spurtpris-js": () => import("./../../../src/pages/resultat/2023/spurtpris.js" /* webpackChunkName: "component---src-pages-resultat-2023-spurtpris-js" */),
  "component---src-pages-resultat-2024-index-js": () => import("./../../../src/pages/resultat/2024/index.js" /* webpackChunkName: "component---src-pages-resultat-2024-index-js" */),
  "component---src-pages-resultat-2024-spurtpris-js": () => import("./../../../src/pages/resultat/2024/spurtpris.js" /* webpackChunkName: "component---src-pages-resultat-2024-spurtpris-js" */),
  "component---src-pages-resultat-index-js": () => import("./../../../src/pages/resultat/index.js" /* webpackChunkName: "component---src-pages-resultat-index-js" */),
  "component---src-pages-startlista-js": () => import("./../../../src/pages/startlista.js" /* webpackChunkName: "component---src-pages-startlista-js" */)
}

